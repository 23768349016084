import {ScrollView, StyleSheet, View} from 'react-native';
import CustomerLogos from './pages/customer';
import Navbar from './pages/navbar';
import Intro from './pages/intro';
import WhatIs from './pages/what_is';
import Footer from './pages/footer';
import {px} from 'csx';
import WhoAreWe from './pages/who_are_we';

export default function App() {
  return (
      <ScrollView style={styles.page}>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link
            href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap"
            rel="stylesheet"/>
        <Navbar/>
        <View style={styles.body}>
          <Intro/>
          <CustomerLogos/>
          <WhatIs/>
          <WhoAreWe/>
        </View>
        <Footer/>
      </ScrollView>
  );
}

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Outfit',
    backgroundColor: '#0ABAB555',
  },
  body: {
    paddingLeft: px(32),
    paddingRight: px(32),
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: px(800),
  },
});
