import React from 'react';
import {
  StyleSheet,
} from 'react-native';

const Intro = () => (
    <div style={styles.intro}>
      <h1>
        Long-Term Partner for Growing Companies
      </h1>
      <text>
        Focused on customized solutions in AI, product, and growth
      </text>
    </div>
);

const styles = StyleSheet.create({
  intro: {
    marginTop: 100,
    textAlign: 'center',
  },
});

export default Intro;
