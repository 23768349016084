import React from 'react';
import {
  Image,
  StyleSheet, View,
} from 'react-native';

const Navbar = () => (
    <header style={styles.header}>
      <nav style={{...styles.navbar, ...styles.navbarFixedTop}}>
        <div style={styles.navbarInner}>
          <a style={styles.brand} href={'/'}>
            <Image source={require('../assets/logos/logo.png')}
                   style={styles.logo}/>
            <text style={styles.title}>MesonTech</text>
          </a>
        </div>
      </nav>
    </header>
);

const styles = StyleSheet.create({
  header: {
    position: 'sticky',
    left: 0,
    width: '100%',
    backgroundColor: '#282c34',
  },
  navbar: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 32,
    paddingLeft: 32,
  },
  navbarFixedTop: {
    top: 0,
    display: 'sticky',
  },
  navbarInner: {
    display: 'flex',
    alignItems: 'center',
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    textDecorationLine: 'none',
  },
  logo: {
    height: 38,
    width: 38,
    borderRadius: 100,
    marginRight: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: '#fff',
  },
});

export default Navbar;
