import React from 'react';
import {
  StyleSheet,
} from 'react-native';

const Footer = () => (
    <div style={styles.footer}>
      <text>MesonTech © 2024. All rights reserved.</text>
    </div>
);

const styles = StyleSheet.create({
  footer: {
    fontSize: 13,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 96,
    paddingBottom: 12,
    color: '#000000AC',
  },
});

export default Footer;
