import React from 'react';
import {
  StyleSheet,
} from 'react-native';

const WhoAreWe = () => (
    <div style={styles.intro}>
      <h1 style={styles.h2}>
        Who are we?
      </h1>
      <text>
        We are from global leading internet companies,
        publicly-listed technology companies, and well-known unicorn enterprises
        in the technology, product, and operations departments.
      </text>
    </div>
);

const styles = StyleSheet.create({
  intro: {
    textAlign: 'center',
  },
});

export default WhoAreWe;
