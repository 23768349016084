import React from 'react';
import {
  StyleSheet,
} from 'react-native';

const WhatIs = () => (
    <div style={styles.intro}>
      <h1>
        What is MesonTech?
      </h1>
      <text>
        Professional, focused, and pragmatic, dedicated to building a top-tier
        technology consulting service and product operation platform.
      </text>
    </div>
);

const styles = StyleSheet.create({
  intro: {
    textAlign: 'center',
  },
});

export default WhatIs;
